import { DefaultPrivacyLevel } from '@datadog/browser-rum';

enum Site {
  US1 = 'datadoghq.com',
}
type ConsoleApiName = 'log' | 'debug' | 'info' | 'warn' | 'error';

export const environment = {
  production: true,

  //Endpoints
  apiLeagueServer: 'https://stgcompeteapi.fullswingapps.com/api',
  apiBaymanagerServer: 'https://stglauncher.fullswingapps.com/api/fs',
  apiBaymanagerPanel: 'https://stgbaymanager.fullswingapps.com',
  apiAuthPanel: 'https://stgauthapi.fullswingapps.com',
  apiAuthServer: 'https://stgauthapi.fullswingapps.com/api',
  //apiLeagueServer: 'https://localhost:44322/api',
  //apiBaymanagerServer: 'https://localhost:44361/api/fs',
  // apiAuthPanel: 'https://localhost:402',
  // apiAuthServer: 'https://localhost:44362/api',

  hostName: 'devbaymanager.fsgapi.net',

  //Local strorage values
  navName: '_fs_nav_name',
  bayManagerUser: 'Bay-Manager-User',
  calendarState: '_fs_fc_state',

  langId: '_fs_lang_id',
  defaultLanguage: 'en',
  defaultFontFamily: 'DavisSans',

  breakPointScreen: {
    small: 576,
    Medium: 768,
    large: 992,
    extraLarge: 1200,
    xExtraLarge: 1400,
  },

  // datadog
  datadog: {
    enableDatadogLogs: false,
    enableDatadogRum: false,
    rumConfig: {
      applicationId: '555b97cb-3bc9-4219-bd0d-96d418904d78',
      clientToken: 'pub40c1a39b4b7f76d562f9f0b0f52a9401',
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: Site.US1,
      service: 'fs-auth-website',
      env: 'staging',
      // Specify a version number to identify the deployed version of your application in Datadog
      version: '1.1.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      defaultPrivacyLevel: DefaultPrivacyLevel.ALLOW,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      // currently tracing not allowed in dev because server needs to be configured
      allowedTracingUrls: ["https://stgauthapi.fullswingapps.com"]
    },
    // make the forwardConsoleLogs only to "warn" using the ConsoleApiName[] required

    logsConfig: {
      clientToken: 'pub40c1a39b4b7f76d562f9f0b0f52a9401',
      site: Site.US1,
      service: 'fs-auth-website',
      env: 'prod',
      version: '1.1.0',
      forwardErrorsToLogs: true,
      forwardConsoleLogs: ['warn', 'error'] as ConsoleApiName[],
      sessionSampleRate: 100,
    },
  },
};
